<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value }"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import options from "@/cfg/options.json";

export default {
  components: {
    fieldsRows
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
              maxDownloadVolume: {
                type: 'text',
                name: 'Max Download Volume',
                colAttrs: {style: 'max-width:378px', class: ''},
                fieldAttrInput: {},
              },
              maxDownloadVolumeUnit: {
                type: 'select',
                name: 'Volume Unit',
                associatedOption: options.downloadVolumeUnit,
                default: 'MB',
                ignoreEmptyAdd: true,
                sort: false,//keep the order!
                colAttrs: {cols: 2},
                fieldAttrInput: {  },
              },
              limitBandwidth: {
                type: 'text',
                name: 'Limit Bandwidth',
                colAttrs: {style: 'max-width:378px', class: ''},
                fieldAttrInput: {},
              },
              limitBandwidthUnit: {
                type: 'select',
                name: 'Bandwidth Unit',
                associatedOption: options.dataUsageLimitBandwidthUnit,
                default: 'kbit/s',
                ignoreEmptyAdd: true,
                colAttrs: {cols: 2},
              },
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      let localValue = {...this.value};
      this.$set(localValue, index, value);
      this.$emit('input', localValue);
    }
  },
};
</script>